import { faHourglassEmpty } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

export const Empty: FC<{ text?: string }> = ({ text = "暂无内容" }) => {
  return (
    <div className="grid size-full place-content-center">
      <div className="flex flex-col items-center gap-4">
        <FontAwesomeIcon icon={faHourglassEmpty} size="2x" />
        <div>{text}</div>
      </div>
    </div>
  );
};
