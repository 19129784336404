"use client";

import { FormField } from "@shadcn-ui/form";
import { Input, InputProps } from "@shadcn-ui/input";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { formatRules } from "../../utils";
import { ProFormItemLayout } from "../components/layout";
import { CommonProFormItemType } from "../types";

interface InputProFormItemProps extends CommonProFormItemType<InputProps> {}

export const InputProForm: FC<InputProFormItemProps> = (params) => {
  const { descsript, fieldProps, label, name, ...props } = params;
  const form = useFormContext();
  return (
    <FormField
      {...props}
      control={form.control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <ProFormItemLayout
          {...params}
          descsript={descsript}
          label={label}
          name={name}
          rules={formatRules(params)}
        >
          <Input placeholder="请输入" {...field} {...fieldProps} />
        </ProFormItemLayout>
      )}
      rules={formatRules(params)}
    />
  );
};
interface InputNumberProFormItemProps
  extends CommonProFormItemType<InputProps> {}

export const InputNumberProForm: FC<InputNumberProFormItemProps> = (params) => {
  const { descsript, fieldProps, label, name, ...props } = params;
  const form = useFormContext();
  return (
    <FormField
      {...props}
      control={form.control}
      defaultValue={undefined}
      name={name}
      render={({ field }) => (
        <ProFormItemLayout
          {...params}
          descsript={descsript}
          label={label}
          name={name}
          rules={formatRules(params)}
        >
          <Input
            placeholder="请输入"
            {...field}
            {...fieldProps}
            onChange={(el) => {
              field.onChange(
                el.target.value ? Number(el.target.value) : undefined,
              );
              fieldProps?.onChange?.(el);
            }}
            type="number"
            value={form.getValues(name)}
          />
        </ProFormItemLayout>
      )}
      rules={formatRules(params)}
    />
  );
};
