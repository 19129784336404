import { wrap } from "comlink";
import { useEffect, useState } from "react";

import { FPSWorkerProps } from "./fps.worker";

export const useFPS = (secondStep = 1) => {
  const [currentFPS, setCurrentFPS] = useState(0);

  // 每秒
  useEffect(() => {
    const worker = new Worker(new URL("./fps.worker.ts", import.meta.url));
    const { getSecondAverageFPS } = wrap<FPSWorkerProps>(
      new Worker(new URL("./fps.worker.ts", import.meta.url)),
    );
    const timer = setInterval(async () => {
      const currentFPS = await getSecondAverageFPS();
      setCurrentFPS(+currentFPS.toFixed(2)); // 四舍五入
    }, secondStep * 1000); // 每x秒更新一次

    return () => {
      worker.terminate();
      clearInterval(timer);
    };
  }, [secondStep]);

  return currentFPS;
};
