type EnumToMapProps = <EnumType extends Record<string, unknown>>(
  enums: EnumType,
) => Map<EnumType[keyof EnumType], keyof EnumType>;
/** ## 枚举 转 Map
 *
 * ### Example
 * ```
 export enum TabEnum {
  'xxx' = 1,
  'xxx' = 2,
  'xxx' = 3,
}
export const TabMap = enumToMap(TabEnum);
export const TabOptions = mapToOptions(TabMap);
 * ```
 */
export const enumToMap: EnumToMapProps = (enums) => {
  type EnumType = typeof enums; // 枚举类型
  type KeyType = keyof EnumType; // 获取枚举的键类型
  const entries = Object.entries(enums) as [KeyType, EnumType[KeyType]][];
  // 过滤出枚举中的字符串键
  const stringEntries = entries.filter(([key]) => isNaN(Number(key)));
  // 创建 Map 并返回
  return new Map(stringEntries.map(([key, value]) => [value, key]));
};

/** ### Map 转 options */
export const mapToOptions = <MapKey, MapValue>(map: Map<MapKey, MapValue>) =>
  Array.from(map, ([value, label]) => ({ label, value }));
