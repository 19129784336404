"use client";
import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { openUrl } from "@utils";
import { getTransitions } from "i18n";
import { FC } from "react";

import { CommonBadge } from "../common-badge";

export const AppSite: FC<{ lang?: string }> = ({ lang }) => {
  const t = getTransitions(lang);
  return (
    <div className="group relative cursor-pointer">
      <CommonBadge
        className="relative z-10 bg-black/50 backdrop-blur-lg"
        icon={faMobile}
        onClick={() => openUrl("https://recordx.app")}
        text={t("navbar.about_app")}
      />
      <div className=" absolute left-0 top-0 z-0 size-full scale-100 bg-purple-400 blur-md transition group-hover:bg-purple-500" />
    </div>
  );
};
