import clsx from "clsx";
import { FC, HTMLAttributes } from "react";

type TypographyProps = FC<HTMLAttributes<HTMLElement>>;

const TypographyH1: TypographyProps = (props) => (
  <h1
    {...props}
    className={clsx(
      "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
      props?.className,
    )}
  >
    {props?.children}
  </h1>
);

const TypographyH2: TypographyProps = (props) => (
  <h2
    {...props}
    className={clsx(
      "scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0",
      props?.className,
    )}
  >
    {props?.children}
  </h2>
);

const TypographyH3: TypographyProps = (props) => (
  <h3
    {...props}
    className={clsx(
      "scroll-m-20 text-2xl font-semibold tracking-tight",
      props?.className,
    )}
  >
    {props?.children}
  </h3>
);

const TypographyH4: TypographyProps = (props) => (
  <h4
    {...props}
    className={clsx(
      "scroll-m-20 text-xl font-semibold tracking-tight",
      props?.className,
    )}
  >
    {props?.children}
  </h4>
);

const TypographyP: TypographyProps = (props) => (
  <div
    {...props}
    className={clsx("leading-7 [&:not(:first-child)]:mt-6", props?.className)}
  >
    {props?.children}
  </div>
);

const TypographyBlockquote: TypographyProps = (props) => (
  <blockquote
    {...props}
    className={clsx("mt-6 border-l-2 pl-6 italic", props?.className)}
  >
    {props?.children}
  </blockquote>
);

const TypographyInlineCode: TypographyProps = (props) => (
  <code
    {...props}
    className={clsx(
      "relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold",
      props?.className,
    )}
  >
    {props?.children}
  </code>
);

const TypographyLead: TypographyProps = (props) => (
  <div
    {...props}
    className={clsx("text-xl text-muted-foreground", props?.className)}
  >
    {props?.children}
  </div>
);

const TypographyLarge: TypographyProps = (props) => (
  <div {...props} className={clsx("text-lg font-semibold", props?.className)}>
    {props?.children}
  </div>
);

const TypographySmall: TypographyProps = (props) => (
  <small
    {...props}
    className={clsx("text-sm font-medium leading-none", props?.className)}
  >
    {props?.children}
  </small>
);

const TypographyMuted: TypographyProps = (props) => (
  <div
    {...props}
    className={clsx("text-sm text-muted-foreground", props?.className)}
  >
    {props?.children}
  </div>
);

/** 统一排版设计
 *
 *  FROM https://ui.shadcn.com/docs/components/typography
 */
export const Typography = {
  Blockquote: TypographyBlockquote,
  H1: TypographyH1,
  H2: TypographyH2,
  H3: TypographyH3,
  H4: TypographyH4,
  InlineCode: TypographyInlineCode,
  Large: TypographyLarge,
  Lead: TypographyLead,
  Muted: TypographyMuted,
  P: TypographyP,
  Small: TypographySmall,
};
