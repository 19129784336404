"use client";
import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";

const GA_ID = "G-YTQSW841LJ";
const GTM_ID = "GTM-TVGBDTK3";

type SendToGTMProps = {
  data?: Record<string, any>;
  event: string;
};
export const sendToGA = (params: SendToGTMProps) => {
  const { data, event } = params;
  window?.dataLayer?.push({ data, event });
  console.log(`window: `, window.dataLayer);
};
/** GA 监听 */
export const GAListen = () => {
  const pathname = usePathname();
  useEffect(() => {
    sendToGA({
      data: { href: window.location.href },
      event: "page_view",
    });
  }, [pathname]);
  return <></>;
};
/** GA Script */
export const GAScript = () => {
  const src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
  const onLoad = () => {
    window.dataLayer = window.dataLayer || [];
    const gtag = (...args: any) => window?.dataLayer?.push?.(args);
    gtag("js", new Date());
    gtag("config", GA_ID);
    window.gtag = gtag;
  };
  return <Script async id="ga4" onLoad={onLoad} src={src} />;
};
/** GTM Script */
export const GTMScript = () => {
  const __html = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');
`;
  return <Script async dangerouslySetInnerHTML={{ __html }} id="gtm" />;
};
/** GTM body Script */
export const GTMBodyScript = () => {
  const src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
  return <iframe className="invisible hidden size-0" src={src} title="gtm" />;
};
