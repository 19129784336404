"use client";

import { SelectProps } from "@radix-ui/react-select";
import { FormField } from "@shadcn-ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@shadcn-ui/select";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { formatRules } from "../../utils";
import { ProFormItemLayout } from "../components/layout";
import { CommonProFormItemType } from "../types";

export type OptionType = { label: string; value: any };
export interface SelectProFormItemProps
  extends CommonProFormItemType<
    Omit<SelectProps, "defaultValue" | "onValueChange">
  > {
  options: OptionType[];
  placeholder?: string;
}

export const SelectProForm: FC<SelectProFormItemProps> = (params) => {
  const {
    descsript,
    fieldProps,
    label,
    name,
    options,
    placeholder = "请选择",
    ...props
  } = params;
  const form = useFormContext();
  return (
    <FormField
      {...props}
      control={form.control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <ProFormItemLayout
          {...params}
          descsript={descsript}
          label={label}
          name={name}
          rules={formatRules(params)}
        >
          <Select
            {...fieldProps}
            defaultValue={field.value}
            onValueChange={(value) => value && field.onChange(value)}
            value={field.value}
          >
            <SelectTrigger>
              <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
              {options.map((item) => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </ProFormItemLayout>
      )}
      rules={formatRules(params)}
    />
  );
};
