"use client";

import { formatRules } from "@components/pro-form/utils";
import { Button } from "@shadcn-ui/button";
import { Calendar, CalendarProps } from "@shadcn-ui/calendar";
import { FormField } from "@shadcn-ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "@shadcn-ui/popover";
import { format } from "date-fns";
import { zhCN } from "date-fns/locale";
import { isArray } from "lodash-es";
import { FC } from "react";
import { DateRange, isDateRange } from "react-day-picker";
import { useFormContext } from "react-hook-form";
import { cn } from "src/shadcn-ui/shadcn-utils";

import { ProFormItemLayout } from "../components/layout";
import { CommonProFormItemType } from "../types";

export type { DateRange } from "react-day-picker";

interface DatePickerProFormItemProps
  extends CommonProFormItemType<Omit<CalendarProps, "onSelect" | "selected">> {
  format?: string;
  // timeStampMode?: boolean; // TODO: WIP
}

/** 日期组件
 * 返回JS `Date` 类型
 */
export const DatePickerProForm: FC<DatePickerProFormItemProps> = (params) => {
  const { descsript, fieldProps, label, name, ...props } = params;
  const form = useFormContext();
  const renderDate = (value: unknown) => {
    const formatStr = "yyyy/MM/dd";
    const isSingle = value instanceof Date;
    const isMultiple =
      isArray(value) && value?.every?.((item) => item instanceof Date);
    if (isSingle) {
      return format(value, formatStr);
    } else if (isMultiple) {
      return value.map((date) => format(date, "yy/MM/dd")).join(",\n");
    } else if (isDateRange(value)) {
      const { from, to } = (value as DateRange) ?? {};
      return `${from ? format(from, formatStr) : ""} - ${to ? format(to, formatStr) : ""}`;
    }
    return "";
  };
  return (
    <FormField
      {...props}
      control={form.control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <ProFormItemLayout
          {...params}
          descsript={descsript}
          label={label}
          name={name}
          rules={formatRules(params)}
        >
          <Popover>
            <PopoverTrigger asChild>
              <div className="flex-1 overflow-hidden">
                <Button
                  className={cn(
                    "w-full pl-3 flex flex-wrap text-left font-normal",
                    !field.value && "text-muted-foreground",
                  )}
                  type="button"
                  variant="outline"
                >
                  {field.value ? renderDate(field.value) : "选择日期"}
                </Button>
              </div>
            </PopoverTrigger>
            <PopoverContent align="start" className="w-auto p-0">
              <Calendar
                locale={zhCN}
                mode="single"
                {...field}
                {...fieldProps}
                onSelect={field.onChange}
                selected={field.value}
              />
            </PopoverContent>
          </Popover>
        </ProFormItemLayout>
      )}
    />
  );
};
