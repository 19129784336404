"use client";

import {
  ControllerProps,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";

import { CommonProFormItemType } from "../form-form-item/types";

export const useProFormReset = (form?: UseFormReturn<any>) => {
  const formctx = useFormContext();
  const { getValues, setValue } = form ?? formctx;
  const reset = () => {
    const fields = Object.keys(getValues());
    fields.forEach((field) => setValue(field, ""));
  };
  return reset;
};
type FormatRulesType = (
  p: CommonProFormItemType<unknown>,
) => ControllerProps["rules"];
export const formatRules: FormatRulesType = (params) => {
  const { label, required, rules } = params;
  const isBooleanRequired = typeof rules?.required === "boolean"; // 是否必填
  const defaultTips = `请填写${label}`; // 默认提示
  const _required = (required || isBooleanRequired) && defaultTips; // 是否必填
  const reason: typeof rules = {
    ...rules,
    required: _required ?? rules?.required,
  };
  return reason;
};
