export default {
  "asset_dashboard.auto_update_desc":
    "Change the settings will be auto updated chart",

  "asset_dashboard.benchmark_line": "Benchmark Line",
  "asset_dashboard.calculation_formula": "Calculation Formula",
  "asset_dashboard.chart_exact_value": "Exact Value",
  "asset_dashboard.chart_max_value": "Max Value",
  "asset_dashboard.chart_min_value": "Min Value",
  "asset_dashboard.chart_net_deposit": "Net Deposit",
  "asset_dashboard.chart_net_deposit_inflation": "Net Deposit(after inflation)",
  "asset_dashboard.chart_prepayment": "Prepayment",
  "asset_dashboard.chart_profit": "Profit",
  "asset_dashboard.chart_remain_amount": "Remain Amount",
  "asset_dashboard.chart_total_amount": "Total Amount",
  "asset_dashboard.chart_turning_point": "Turning Point",
  "asset_dashboard.chart_year_later": "Year Later",
  "asset_dashboard.money": "Amount of deposit",
  "asset_dashboard.opt_fifty_years": "50 years",
  "asset_dashboard.opt_five_years": "5 years",
  "asset_dashboard.opt_one_month": "1 month",
  "asset_dashboard.opt_one_year": "1 year",
  "asset_dashboard.opt_six_months": "6 months",
  "asset_dashboard.opt_ten_years": "10 years",
  "asset_dashboard.opt_thirty_years": "30 years",
  "asset_dashboard.opt_three_months": "3 months",
  "asset_dashboard.opt_three_years": "3 years",
  "asset_dashboard.prepayment": "Prepayment/month",
  "asset_dashboard.prepayment_desc": "Such as fixed monthly expenses",
  "asset_dashboard.profit_rate_daily": "Profit/day",
  "asset_dashboard.profit_rate_yearly": "Profit/year",
  "asset_dashboard.short_term_yield": "Short term yield",
  "asset_dashboard.short_term_yield_desc":
    "If the 'Expected Return' matches your 'Actual Return', the chart on the right will be more accurate.",
  "asset_dashboard.time_span": "Settlement cycle",
  "asset_dashboard.title": "Statistical Data",
  "asset_dashboard.view_data_source": "View Data Source",
  "asset_dashboard.view_time": "View time",
  "asset_dashboard.view_time_desc": "Control the time range of the right view",
  "asset_dashboard.yearly_inflation_rate": "Inflation Rate/year",
};
