"use client";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFPS } from "@hooks";
import { Badge } from "@shadcn-ui/badge";
import { Button } from "@shadcn-ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@shadcn-ui/tooltip";
import { openUrl } from "@utils";
import { motion } from "framer-motion";
import { FC } from "react";

export const FPSMonitor: FC = () => {
  const fps = useFPS();
  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge className="flex h-7 gap-2" variant="outline">
          <div className="font-mono font-bold">{fps.toFixed(2)} FPS</div>
          <FontAwesomeIcon className="size-3" icon={faDesktop} />
          <span className="relative flex size-2">
            <motion.span
              animate={{
                opacity: [0, 0.75, 0],
                scale: [1, 1.5, 3],
              }}
              className="absolute inline-flex size-full rounded-full bg-green-400 blur-sm"
              transition={{
                duration: 1, // 增加动画持续时间
                repeat: Infinity,
              }}
            />
            <span className="relative inline-flex size-2 rounded-full bg-green-500" />
          </span>
        </Badge>
      </TooltipTrigger>
      <TooltipContent className="text-center">
        <div className="flex flex-col gap-1">
          <div className="font-bold">当前帧率</div>
          <div className="text-xs">
            <div>应用已进行优化</div>
            <div>如有卡顿，可点击下方按钮反馈</div>
          </div>
          <Button
            className="text-xs text-white"
            onClick={() => openUrl("mailto:zrecords.team@gmail.com")}
            variant="outline"
          >
            立即反馈至作者邮箱
          </Button>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
