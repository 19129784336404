export default {
  "common.amount_unit": "元",
  "common.amount_unit_symbol": "￥",
  "common.date_unit_day": "天",
  "common.date_unit_month": "月",
  "common.date_unit_week": "周",
  "common.date_unit_year": "年",
  "common.download": "下载",
  "common.feedback": "反馈",
  "common.help": "帮助",
  "common.per_day": "每日",
  "common.per_month": "每月",
  "common.per_week": "每周",
  "common.per_year": "每年",
  "common.reset": "重置",
  "common.settings": "设置",
  "common.sign_in": "登录",
  "common.sign_out": "登出",
  "common.sign_up": "注册",
  "common.submit": "提交",
  "common.user": "用户",
};
