import { useProFormContext } from "@components/pro-form/form-context";
import { ProFormResetButton } from "@components/pro-form/reset-button";
import { ProFormSubmitButton } from "@components/pro-form/submit-button";
import clsx from "clsx";
import { FC } from "react";

export interface FormActionsProps {
  actions?: {
    resetButton?: boolean;
    submitButton?: boolean;
  } & boolean;
  actionsAlign?: "center" | "left" | "right";
}
/** 渲染 FormActions */
export const FormActions: FC<FormActionsProps> = ({
  actions: { resetButton = true, submitButton = true } = {},
  actionsAlign = "center",
}) => {
  const { handleSubmit } = useProFormContext();
  return (
    <div
      className={clsx(
        "mt-4 flex items-center gap-2",
        actionsAlign === "left" && "justify-start",
        actionsAlign === "center" && "justify-center",
        actionsAlign === "right" && "justify-end",
      )}
    >
      {resetButton && <ProFormResetButton />}
      {submitButton && <ProFormSubmitButton onSubmit={handleSubmit} />}
    </div>
  );
};
