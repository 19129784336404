"use client";

import { Button, ButtonProps } from "@shadcn-ui/button";
import { getTransitions } from "i18n";
import { FC } from "react";
import { FieldValues, useFormContext, UseFormReturn } from "react-hook-form";

import { useProFormContext } from "../form-context";

type ProFormSubmitButtonProps<
  FormDataType extends FieldValues = Record<string, unknown>,
> = {
  form?: UseFormReturn<FormDataType>;
  onSubmit?: (formData: FieldValues) => void;
} & Omit<ButtonProps, "form" | "onSubmit">;

export const ProFormSubmitButton: FC<ProFormSubmitButtonProps> = (params) => {
  const t = getTransitions();

  const { children, form, ...props } = params;
  const formctx = useFormContext();
  const { handleSubmit } = useProFormContext();
  const formInstance = form ?? formctx;

  const handleMouseDown = async (e: React.MouseEvent<HTMLButtonElement>) => {
    props?.onMouseDown?.(e);
    const isVerify = await formInstance.trigger();
    if (isVerify) {
      handleSubmit?.();
      props?.onSubmit?.(formInstance.getValues());
    }
  };
  return (
    <>
      <Button {...props} onMouseDown={handleMouseDown} type="button">
        {children ?? t("common.submit")}
      </Button>
      <input hidden type="submit" />
    </>
  );
};
