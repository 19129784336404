"use client";
import { TooltipProvider } from "@shadcn-ui/tooltip";
import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export const QueryWrapper: FC<{ children: React.ReactNode }> = (props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider delayDuration={0}>{props.children}</TooltipProvider>
    </QueryClientProvider>
  );
};

export default QueryWrapper;
