import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, BadgeProps } from "@shadcn-ui/badge";
import clsx from "clsx";
import { FC } from "react";

type CommonBadgeProps = {
  icon: IconDefinition;
  text: string;
} & BadgeProps;
export const CommonBadge: FC<CommonBadgeProps> = (props) => {
  const { icon, text } = props;
  return (
    <Badge
      variant="outline"
      {...props}
      className={clsx("flex h-7 gap-2", props.className)}
    >
      <span className="text-xs">{text}</span>
      <FontAwesomeIcon className="size-3" icon={icon} />
    </Badge>
  );
};
