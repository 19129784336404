import { User } from "@supabase/supabase-js";
import { create } from "zustand";

type State = {
  isLogin: boolean;
  userInfo: null | User;
};

type Action = {
  updateLogin: (val: State["isLogin"]) => void;
  updateUserInfo: (val: State["userInfo"]) => void;
};

export const useGlobalStore = create<Action & State>((set) => ({
  isLogin: false,
  updateLogin: (isLogin) => set({ isLogin }),
  updateUserInfo: (userInfo) => set({ userInfo }),
  userInfo: null,
}));
