export default {
  "asset_dashboard.auto_update_desc": "更改设置将自动更新图表",
  "asset_dashboard.benchmark_line": "基准线",
  "asset_dashboard.calculation_formula": "计算公式",
  "asset_dashboard.chart_exact_value": "精确值",
  "asset_dashboard.chart_max_value": "最大值",
  "asset_dashboard.chart_min_value": "最小值",
  "asset_dashboard.chart_net_deposit": "净存款金额",
  "asset_dashboard.chart_net_deposit_inflation": "净存款金额(通胀后)",
  "asset_dashboard.chart_prepayment": "预付款",
  "asset_dashboard.chart_profit": "收益",
  "asset_dashboard.chart_remain_amount": "剩余金额",
  "asset_dashboard.chart_total_amount": "总额",
  "asset_dashboard.chart_turning_point": "拐点",
  "asset_dashboard.chart_year_later": "年后",
  "asset_dashboard.money": "存款金额",
  "asset_dashboard.opt_fifty_years": "50 年",
  "asset_dashboard.opt_five_years": "5 年",
  "asset_dashboard.opt_one_month": "1 月",
  "asset_dashboard.opt_one_year": "1 年",
  "asset_dashboard.opt_six_months": "6 月",
  "asset_dashboard.opt_ten_years": "10 年",
  "asset_dashboard.opt_thirty_years": "30 年",
  "asset_dashboard.opt_three_months": "3 月",
  "asset_dashboard.opt_three_years": "3 年",
  "asset_dashboard.prepayment": "预付款/月",
  "asset_dashboard.prepayment_desc": "如每月固定开销",
  "asset_dashboard.profit_rate_daily": "收益率/天",
  "asset_dashboard.profit_rate_yearly": "收益率/年",
  "asset_dashboard.short_term_yield": "短期收益",
  "asset_dashboard.short_term_yield_desc":
    "如果上面「预计收益」与您「现实收益」相符合，则右侧图表越精准",
  "asset_dashboard.time_span": "结算周期",
  "asset_dashboard.title": "数据统计",
  "asset_dashboard.view_data_source": "查看数据来源",
  "asset_dashboard.view_time": "视图周期",
  "asset_dashboard.view_time_desc": "控制右方视图的时间范围",
  "asset_dashboard.yearly_inflation_rate": "通胀率/年",
};
