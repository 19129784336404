import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type FixedSpinnerProps = {
  spinner?: boolean;
} & JSX.IntrinsicElements["div"];

export const FixedSpinner: FC<FixedSpinnerProps> = (params) => {
  const { children, spinner = true, ...props } = params;
  if (!spinner) return children;
  return (
    <div className="grid size-full place-items-center" {...props}>
      <FontAwesomeIcon className="animate-spin text-3xl" icon={faSpinner} />
    </div>
  );
};
