import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "@shadcn-ui/button";
import { FC } from "react";

export const ResetIcon: FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      className="text-xs"
      size="icon"
      type="button"
      variant="ghost"
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  );
};
