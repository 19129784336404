import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@shadcn-ui/skeleton";
import React, { FC } from "react";

export const FixedSkeleton: FC = () => {
  return (
    <Skeleton className="grid size-full place-content-center rounded-xl">
      <FontAwesomeIcon className="animate-spin text-3xl" icon={faSpinner} />
    </Skeleton>
  );
};

export default FixedSkeleton;
