import {
  faChartLine,
  faChartSimple,
  faGear,
  faHeadset,
  // faPieChart,
  // faRobot,
  faSackDollar,
  faUser,
  // faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTransitions, TransitionsType } from "i18n";
import { getLocale } from "i18n/utils";
import { ReactNode } from "react";

type NavData = {
  icon?: ReactNode;
  text: string;
  to: string;
};
export const getNavData: (p: { t: TransitionsType }) => NavData[] = ({ t }) => {
  return [
    // {
    //   icon: <FontAwesomeIcon className="size-4" icon={faRobot} />,
    //   text: "聊天室",
    //   to: "/chart-room",
    // },
    // {
    //   icon: <FontAwesomeIcon className="size-4" icon={faRobot} />,
    //   text: "Sora",
    //   to: "/video",
    // },
    // {
    //   text: "项目",
    //   to: "/project",
    //   icon: <FontAwesomeIcon className="size-4" icon={faFolderOpen} />,
    // },
    // {
    //   icon: <FontAwesomeIcon className="size-4" icon={faWarehouse} />,
    //   text: "仓库",
    //   to: "/warehouse",
    // },
    {
      icon: <FontAwesomeIcon className="size-4" icon={faSackDollar} />,
      text: t("navbar.asset_dashboard"),
      to: "/asset-dashboard",
    },
    // {
    //   icon: <FontAwesomeIcon className="size-4" icon={faPieChart} />,
    //   text: t("navbar.analytics"),
    //   to: "/analytics",
    // },
    // {
    //   icon: <FontAwesomeIcon className="size-4" icon={faLineChart} />,
    //   text: t("navbar.stock_dashboard"),
    //   to: "/stock-dashboard",
    // },
    {
      icon: <FontAwesomeIcon className="size-4" icon={faChartSimple} />,
      text: t("navbar.market_status"),
      to: "/market-status",
    },
    {
      icon: <FontAwesomeIcon className="size-4" icon={faChartLine} />,
      text: "Tracker",
      to: "/finance-tracker",
    },
    // {
    //   icon: <FontAwesomeIcon className="size-4" icon={faNewspaper} />,
    //   text: "时事新闻",
    //   to: "/news",
    // },
    // {
    //   text: "数据面板",
    //   to: "/dashboard",
    //   icon: <FontAwesomeIcon className="size-4" icon={faDashboard} />,
    // },
  ];
};

export const getSysNavData: () => NavData[] = () => {
  const locale = getLocale();
  const t = getTransitions(locale);
  return [
    {
      icon: <FontAwesomeIcon className="size-4" icon={faUser} />,
      text: t("common.user"),
      to: "/login",
    },
    {
      icon: <FontAwesomeIcon className="size-4" icon={faHeadset} />,
      text: t("common.feedback"),
      to: "/feedback",
    },
    {
      icon: <FontAwesomeIcon className="size-4" icon={faGear} />,
      text: t("common.settings"),
      to: "/setting",
    },
  ];
};
