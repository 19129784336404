import clsx from "clsx";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";

type SideNavItemProps = {
  isCollapse?: boolean;
} & HTMLAttributes<HTMLDivElement> &
  PropsWithChildren;
export const SideNavItem = forwardRef<HTMLDivElement, SideNavItemProps>(
  ({ children, isCollapse = true, ...props }, ref) => (
    <div
      {...props}
      className={clsx(
        props.className,
        "flex w-full cursor-pointer items-center rounded-lg p-3 text-base transition-colors hover:bg-secondary",
        isCollapse ? "size-10" : "h-10",
      )}
      ref={ref}
    >
      <div className="w-full overflow-hidden">{children || "-"}</div>
    </div>
  ),
);
SideNavItem.displayName = "SideNavItem";
