"use client";

import { FormField } from "@shadcn-ui/form";
import { Textarea, TextareaProps } from "@shadcn-ui/textarea";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { ProFormItemLayout } from "../components/layout";

interface TextareaProFormItemProps {
  fieldProps?: TextareaProps;
  label?: string;
  name: string;
}

export const TextareaProForm: FC<TextareaProFormItemProps> = (params) => {
  const { fieldProps, label, name } = params;
  const form = useFormContext();

  return (
    <FormField
      control={form.control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <ProFormItemLayout {...params} label={label} name={name}>
          <Textarea placeholder="请输入" {...fieldProps} {...field} />
        </ProFormItemLayout>
      )}
    />
  );
};
