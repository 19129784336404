// "use client";
// import { useGlobalStore } from "@/store";
import {
  faDownload,
  faHeadset,
  faHeart,
  faTag,
  // faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { getTransitions } from "i18n";
import Image from "next/image";
import Link from "next/link";
import LogoX from "public/logo_x.png";
import { FC, memo } from "react";

import pkg from "../../../package.json";
import { Badge } from "../../shadcn-ui/ui/badge";
import { Typography } from "../typography";
import {
  AppSite,
  CommonBadge,
  CommonButton,
  FPSMonitor,
  Language,
} from "./components";
// import { AccountButton } from "./components/account-button";
import styles from "./index.module.css";

export const Toolbar: FC<{ lang?: string }> = ({ lang }) => {
  const isElectron = process.env.NEXT_PUBLIC_IS_ELECTRON;
  const t = getTransitions(lang);
  // const { isLogin } = useGlobalStore();
  const ProductHunt: FC = () => {
    return (
      <div className="group relative cursor-pointer">
        <Badge
          className="relative z-10 flex h-7 gap-2 bg-black/50 backdrop-blur-lg"
          // onClick={() => openUrl("https://recordx.app")}
          variant="outline"
        >
          <FontAwesomeIcon icon={faHeart} />
        </Badge>
        <div className=" absolute left-0 top-0 z-0 size-full scale-100 bg-orange-400 blur-md transition group-hover:bg-orange-500" />
      </div>
    );
  };
  const Feedback: FC = () => {
    return (
      <Link href={"/" + lang + "/feedback"}>
        <CommonButton icon={faHeadset} text={t("common.feedback")} />
      </Link>
    );
  };
  const Download: FC = () => (
    <CommonButton disabled icon={faDownload} text={t("common.download")} />
  );
  const VersionTag: FC = () => {
    return <CommonBadge icon={faTag} text={`v${pkg.version}`} />;
  };

  return (
    <div
      className={clsx(
        styles.toolbar,
        "sticky top-0 z-20 size-full text-nowrap rounded-br-3xl bg-card/50 p-2 pr-4 text-xs text-foreground backdrop-blur-2xl",
      )}
    >
      <div
        className={clsx(
          styles.nodrag,
          "flex size-full items-center justify-between gap-3",
        )}
      >
        <div className={clsx("flex items-center gap-2", isElectron && "ml-20")}>
          <Link href={"/" + lang}>
            <Typography.H3 className="flex items-center gap-1">
              <Image
                alt=""
                className="pointer-events-none size-10"
                src={LogoX}
              />
              <div className="bg-gradient-to-br from-orange-400 via-orange-200 to-orange-200 bg-clip-text font-bold tracking-widest text-transparent">
                DASHBOARD
              </div>
            </Typography.H3>
          </Link>
        </div>
        <div className="flex gap-3">
          <AppSite lang={lang} />
          <ProductHunt />
          <div className="my-2 h-auto w-[2px] rounded-full bg-secondary" />
          <Download />
          <Feedback />
          <div className="my-2 h-auto w-[2px] rounded-full bg-secondary" />
          <FPSMonitor />
          <VersionTag />
          <Language />
          <div className="my-2 h-auto w-[2px] rounded-full bg-secondary" />
          {/* {isLogin ? (
            <AccountButton />
          ) : (
            <CommonButton disabled icon={faUser} text={t("common.sign_in")} />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default memo(Toolbar);
