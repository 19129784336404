import { defaultLocale } from "i18n/constants";
import messages from "i18n/message";

export type MessagesType = typeof messages;
export type MessageKeys = keyof MessagesType[keyof MessagesType];

/** get locale
 * for Client Side
 */
export const getLocale = (): keyof MessagesType => {
  if (globalThis.window === undefined) return defaultLocale;
  const lang = document.documentElement.lang;
  return (lang as keyof MessagesType) ?? defaultLocale;
};

export const changeLocale = (v: string): void => {
  const list = window.location.pathname.split("/");
  list.splice(1, 1, v);
  window.location.href = list.join("/");
};
