"use client";
import { QueryWrapper } from "@components";
import { supportedLocales } from "i18n/constants";
import { changeLocale, getLocale } from "i18n/utils";
import localforage from "localforage";
import React, { PropsWithChildren, useEffect } from "react";

/** 公共包裹器 */
export const Wrappers: React.FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    window?.electronAPI?.onSendToRenderer("message", (data) => {
      console.log("message", data);
    });
  }, []);
  const checkLocale = async () => {
    const curLocale = getLocale(); // 当前语言
    const lang = navigator.language.split("-")[0]; // 获取用户浏览器语言
    const isSameLocale = curLocale === lang; // 是否同一语言
    const isSupported = supportedLocales.includes(lang); // 是否支持
    const localLang = await localforage.getItem("lang"); // 获取本地存储的语言
    const isCacheLocale = lang === localLang; // 是否已经缓存
    if (!isSameLocale && isSupported && !isCacheLocale) {
      changeLocale(lang);
      localforage.setItem("lang", lang);
    }
  };
  // 检查用户浏览器语言
  useEffect(() => {
    checkLocale();
  }, []);

  return <QueryWrapper>{children}</QueryWrapper>;
};
