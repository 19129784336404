"use client";

import { formatRules } from "@components/pro-form/utils";
import { SwitchProps } from "@radix-ui/react-switch";
import { FormField } from "@shadcn-ui/form";
import { Switch } from "@shadcn-ui/switch";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { ProFormItemLayout } from "../components/layout";
import { CommonProFormItemType } from "../types";

type SwitchProFormItemProps = CommonProFormItemType<SwitchProps>;

export const SwitchProForm: FC<SwitchProFormItemProps> = (params) => {
  const { descsript, fieldProps, label, name, ...props } = params;
  const form = useFormContext();
  return (
    <FormField
      {...props}
      control={form.control}
      name={name}
      render={({ field }) => (
        <ProFormItemLayout
          {...params}
          descsript={descsript}
          label={label}
          name={name}
          rules={formatRules(params)}
        >
          <Switch
            {...field}
            {...fieldProps}
            checked={field.value}
            onCheckedChange={field.onChange}
          />
        </ProFormItemLayout>
      )}
    />
  );
};
