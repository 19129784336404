"use client";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownMenuRadioGroup } from "@radix-ui/react-dropdown-menu";
import { Badge } from "@shadcn-ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@shadcn-ui/dropdown-menu";
import { changeLocale, getLocale } from "i18n/utils";
import { FC } from "react";

export const Language: FC = () => {
  const lang = getLocale();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Badge
          className="relative z-10 flex h-7 gap-2 bg-black/50 backdrop-blur-lg"
          variant="outline"
        >
          <FontAwesomeIcon icon={faGlobe} size="lg" />
        </Badge>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuRadioGroup
          onValueChange={(value) => changeLocale(value)}
          value={lang}
        >
          <DropdownMenuRadioItem value="en">🇺🇸 English</DropdownMenuRadioItem>
          <DropdownMenuRadioItem defaultChecked value="zh">
            🇨🇳 中文
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
