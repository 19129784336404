export default {
  "common.amount_unit": "Dollars",
  "common.amount_unit_symbol": "$",
  "common.date_unit_day": "Day",
  "common.date_unit_month": "Month",
  "common.date_unit_week": "Week",
  "common.date_unit_year": "Year",
  "common.download": "Download",
  "common.feedback": "Feedback",
  "common.help": "Help",
  "common.per_day": "per day",
  "common.per_month": "per month",
  "common.per_week": "per week",
  "common.per_year": "per year",
  "common.reset": "Reset",
  "common.settings": "Settings",
  "common.sign_in": "Sign In",
  "common.sign_out": "Sign Out",
  "common.sign_up": "Sign Up",
  "common.submit": "Submit",
  "common.user": "User",
};
