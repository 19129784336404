"use client";
import { useGlobalStore } from "@/store";
import {
  faCrown,
  faSignOut,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, AvatarFallback, AvatarImage } from "@shadcn-ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@shadcn-ui/dropdown-menu";
import { FC } from "react";

export const AccountButton: FC = () => {
  const { updateLogin } = useGlobalStore();
  const onLogout = () => {
    updateLogin(false);
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar className="size-6">
          <AvatarImage alt="@shadcn" src="https://github.com/shadcn.png" />
          <AvatarFallback>用户</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>我的账号</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <FontAwesomeIcon className="mr-2 size-4" icon={faWallet} />
          <span>账单</span>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <FontAwesomeIcon className="mr-2 size-4" icon={faUsers} />
          <span>团队</span>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <FontAwesomeIcon className="mr-2 size-4" icon={faCrown} />
          <span>订阅</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={onLogout}>
          <FontAwesomeIcon className="mr-2 size-4" icon={faSignOut} />
          <span>登出</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
