import assetDashboard from "./asset-dashboard";
import common from "./common";
import home from "./home";
import navbar from "./navbar";

export const zh = {
  ...common,
  ...home,
  ...navbar,
  ...assetDashboard,
};
