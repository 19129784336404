import { HTMLAttributeAnchorTarget } from "react";

/** 打开链接
 * @description 如果是electron环境，则使用electronAPI打开链接
 */
export const openUrl = (
  url: string,
  target: HTMLAttributeAnchorTarget = "_blank",
) => {
  const isElectron = window?.electronAPI?.isElectron;
  isElectron ? window?.electronAPI?.openUrl(url) : window.open(url, target);
};
