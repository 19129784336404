import messages from "./message/index";
import { getLocale, MessageKeys, MessagesType } from "./utils";

export * from "./utils";

/**
 * example:
 * ```ts
 * const title = t('title')
 * ```
 */
export const t = (key: MessageKeys) => {
  const _t = getTransitions();
  return _t(key);
};

/** for SSG
 * if pass lang, it will prefer build, better for SEO
 * example:
 * ```ts
 * const t = getTranslate()
 * const title = t('title')
 * ```
 */
export const getTransitions = (lang?: string) => {
  const _lang = lang ?? getLocale(); // getLocale() for CSR
  return (key: MessageKeys) => messages?.[_lang as keyof MessagesType]?.[key];
};

export type TransitionsType = ReturnType<typeof getTransitions>;
export type LangProps = { params: { lang?: string } }; // 动态路由参数
