"use client";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { getTransitions } from "i18n";
import { usePathname, useRouter } from "next/navigation";
import { FC, useEffect, useState } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn-ui/ui/tooltip";
import { Typography } from "../typography";
import { getNavData, getSysNavData } from "./constants";
import { SideNavItem } from "./side-nav-item";

export const SideNavBar: FC<{ lang?: string }> = ({ lang }) => {
  const t = getTransitions(lang);
  const router = useRouter();
  const pathname = usePathname();
  const [isCollapse, setIsCollapse] = useState(true); // 是否收起侧边导航
  const navData = getNavData({ t });
  const sysNavData = getSysNavData();

  useEffect(() => {
    const cacheCollapse = localStorage.getItem("isCollapse") === "true";
    if (cacheCollapse !== null) {
      setIsCollapse(cacheCollapse);
    }
  }, []);

  const toggleCollapse = () => {
    const nextVal = !isCollapse;
    setIsCollapse(nextVal);
    localStorage.setItem("isCollapse", nextVal.toString());
  };

  const renderSideNavItem = ({
    icon,
    text,
    to,
  }: ReturnType<typeof getNavData>[0]) => (
    <TooltipProvider
      delayDuration={0}
      disableHoverableContent={!isCollapse}
      key={to}
    >
      <Tooltip>
        <TooltipTrigger asChild defaultChecked={false}>
          <SideNavItem
            className={clsx(pathname === `/${lang}` + to && "bg-secondary")}
            onClick={() => router.push(`/${lang}` + to)}
          >
            <Typography.Small>
              <div
                className="flex items-center gap-2"
                style={{ justifyContent: isCollapse ? "center" : "start" }}
              >
                <span>{icon}</span>
                {!isCollapse && <span className="line-clamp-1">{text}</span>}
              </div>
            </Typography.Small>
          </SideNavItem>
        </TooltipTrigger>
        <TooltipContent side="right">{text}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
  return (
    <div
      className="flex h-full flex-none flex-col justify-between rounded-br-2xl bg-card/50 p-2 pb-4 text-foreground backdrop-blur-2xl transition-all"
      style={{ width: isCollapse ? "56px" : "128px" }}
    >
      <div className="flex flex-col justify-between gap-1 overflow-hidden rounded">
        {navData.map(renderSideNavItem)}
      </div>
      <div className="grid gap-1">
        <div className="my-2 h-[2px] w-full rounded-full bg-foreground/50" />
        {sysNavData.map(renderSideNavItem)}
        <button
          className="grid cursor-pointer place-items-center rounded-lg p-2 transition-colors hover:bg-secondary"
          onClick={toggleCollapse}
        >
          <FontAwesomeIcon
            className="size-4"
            icon={isCollapse ? faAngleRight : faAngleLeft}
          />
        </button>
      </div>
    </div>
  );
};

export default SideNavBar;
