import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "@shadcn-ui/button";
import clsx from "clsx";
import Link, { LinkProps } from "next/link";
import React, { FC } from "react";

type CommonProps = {
  icon: IconDefinition;
  text: React.ReactNode;
} & (ButtonProps | LinkProps);

export const CommonButton: FC<CommonProps> = (props) => {
  const { icon, text, ...rest } = props;
  const ButtonInstance: FC<ButtonProps> = (props) => (
    <Button
      variant="outline"
      {...props}
      className={clsx("flex h-7 gap-2", props.className)}
    >
      <span className="text-xs">{text}</span>
      <FontAwesomeIcon className="size-3" icon={icon} />
    </Button>
  );
  if ("href" in rest) {
    return (
      <Link {...rest} href={rest.href}>
        <ButtonInstance />
      </Link>
    );
  } else {
    return <ButtonInstance {...rest} />;
  }
};
